// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1727600610622.0566";
}
// REMIX HMR END

import CTA from "~/components/ctaBtn";
import SocialMediaIcons from "~/components/socialMediaGrid";
import FAQ from "~/components/faq";
import platforms from "~/images/platforms.png";
import socialMedia from "~/data/socialMedia";
import apps from "~/data/apps";
import ValuesSection from "~/components/valuesSection";
import HomeHero from "~/components/homeHero";
import ShowGrid from "~/components/showsGrid";
import HomeClosing from "~/components/homeClosing";
import { useFetcher } from "@remix-run/react";
import AppLinks from "~/components/appLinks";
import { useEffect, useState } from "react";
import logo from "~/images/logo.png";
export const meta = () => {
  return [{
    title: "Salam Kids TV",
    property: "og:title",
    name: "title"
  }, {
    property: "og:description",
    name: "description",
    content: `Upbringing begins with Salam!\n
تربیت کا آغاز سلام کے ساتھ!`
  }, {
    property: "og:image",
    content: logo,
    name: "logo"
  }, {
    title: "Salam Kids TV",
    property: "twitter:title",
    name: "title"
  }, {
    property: "twitter:description",
    name: "description",
    content: `Upbringing begins with Salam!\n
تربیت کا آغاز سلام کے ساتھ!`
  }, {
    property: "twitter:image",
    content: logo,
    name: "logo"
  }];
};
export default function Index() {
  _s();
  const [socials, setSocials] = useState(socialMedia);
  const [appLinks, setAppLinks] = useState(apps);
  const fetcherAPI = useFetcher();
  useEffect(() => {
    fetcherAPI.load("/api/getSettingsData");
  }, []);
  useEffect(() => {
    if (!fetcherAPI || !fetcherAPI.data || fetcherAPI.state === "loading") {
      return;
    }
    if (fetcherAPI.data) {
      const socialLinks = fetcherAPI.data.socialLinks;
      const appStoreLinks = fetcherAPI.data.appLinks;
      setSocials(socialLinks);
      setAppLinks(appStoreLinks);
    }
  }, [fetcherAPI.data]);
  return <>
      <HomeHero />
      <ValuesSection />
      <div className="section">
        <h2 className="heading-text">
          Hand picked by our Experts
          <br />
          Safe for your kids
        </h2>
        <ShowGrid />
        <CTA link="/signup" text="Explore Now" />
      </div>
      <div className="section">
        <h2 className="heading-text">Get Our Apps</h2>
        <AppLinks social_links={appLinks} />
      </div>
      <div className="section">
        <h2 className="heading-text">We will be available on</h2>
        <img src={platforms} alt="" className="size-transition full-width" />
        <CTA link="/signup" text="Sign up for FREE" />
      </div>
      <div className="section">
        <h2 className="heading-text">Join Our Community</h2>
        <SocialMediaIcons social_links={socials} />
      </div>
      <div className="" style={{
      display: "flex",
      justifyContent: "center",
      flexDirection: "column"
    }}>
        <h2 className="heading-text" style={{
        textAlign: "center"
      }}>
          Frequently asked questions
        </h2>
        <FAQ />
      </div>
      <HomeClosing />
    </>;
}
_s(Index, "6Io4V/BzdzoGcw/eCXK1tozKgGg=", false, function () {
  return [useFetcher];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;